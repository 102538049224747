.App {
  text-align: center;
}



.sample-receipt {
  border: 1px solid #000;
  padding: 10px;
  margin: 10px 0;
  width: 300px;
  /* bottom: -400px; */
  position:relative;
  z-index: 9;
  background-color: #fff;
  font-family: monospace;
  color: #000;
}

.sample-receipt .text {
  font-size: 14px;
}

.sample-receipt .bold {
  font-weight: bold;
}

.sample-receipt .line {
  border-bottom: 1px dashed #000;
  margin: 5px 0;
}

.sample-receipt .row {
  display: flex;
  justify-content: space-between;
}

.sample-receipt .br {
  height: 10px;
}

.sub-text-color {
  color:white;
}

.main-background-color {
  background-color: #E8EDED !important;
}

.sub-background-color {
  background-color: #d0d5d5 !important;
}

.main-btn-color {
  background-color:#FF8339 !important;
  /* opacity: 0.6; */
}

.sub-modal-background-color {
  background-color: #eaeeee !important;
}

.sub-head-btn-background-color {
  background-color: #f1f4f4 !important;
}

.main-text-color {
    color: #008cff; 
    /* opacity: 0.6; */
}
.table-backgroud-header-footer {
  background-color: #a2a5a5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* sbchoi receipt TestCode */
.sample-receipt {
  border: 1px solid #000;
  padding: 10px;
  margin: 10px 0;
  width: 400px;
  background-color: #fff;
  font-family: monospace;
  color: #000;
}

.sample-receipt .text {
  font-size: 14px;
}

.sample-receipt .bold {
  font-weight: bold;
}

.sample-receipt .line {
  border-bottom: 1px dashed #000;
  margin: 5px 0;
}

.sample-receipt .row {
  display: flex;
  justify-content: space-between;
}

.sample-receipt .br {
  height: 10px;
}

.rtp-row {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.rtp-row-left,
.rtp-row-right {
  flex: 1;
}

.rtp-row-left {
  text-align: left;
}

.rtp-row-right {
  text-align: right;
}


/* FullCalendar 각 셀의 높이 설정 */
.fc {
  max-height: 60vh !important;
  /* 셀 높이 설정 */
}

/* 일요일 날짜: 빨간색 */
.fc-day-sun a {
  color: red;
}

/* 토요일 날짜: 파란색 */
.fc-day-sat a {
  color: blue;
}

/* FullCalendar 이벤트 스타일 재정의 */
.fc .fc-event {
  background-color: transparent !important;
  /* 배경색 제거 */
  color: black !important;
  /* 텍스트 색상을 검정색으로 설정 */
  border: none !important;
  /* 테두리 제거 */
}

.fc-h-event .fc-event-main {
  color: black !important;
}



@media (max-width: 800px) {
  .demo-app-main {
    font-size: 2.2vw;
  }
}

@media (max-width: 500px) {
  .demo-app-main {
    font-size: 3vw;
  }
}





@keyframes waveAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes sirenBellAnimation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-30deg);
  }

  20% {
    transform: rotate(30deg);
  }

  30% {
    transform: rotate(-24deg);
  }

  40% {
    transform: rotate(24deg);
  }

  50% {
    transform: rotate(-18deg);
  }

  60% {
    transform: rotate(18deg);
  }

  70% {
    transform: rotate(-12deg);
  }

  80% {
    transform: rotate(12deg);
  }

  90% {
    transform: rotate(-6deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes leftRightAnimation {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(-5px);
  }

  20% {
    transform: translateY(5px);
  }

  30% {
    transform: translateY(-4px);
  }

  40% {
    transform: translateY(4px);
  }

  50% {
    transform: translateY(-3px);
  }

  60% {
    transform: translateY(3px);
  }

  70% {
    transform: translateY(-2px);
  }

  80% {
    transform: translateY(2px);
  }

  90% {
    transform: translateY(-1px);
  }

  100% {
    transform: translateY(0);
  }
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: waveAnimation 1s ease-in-out infinite;
  }

  .alertText {
    animation: leftRightAnimation 0.5s ease-in-out;
  }

  .sirenBell {
    transform-origin: top center;
    /* 상단을 고정시킴 */
    animation: sirenBellAnimation 1s ease-in-out infinite;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* 모바일 전용 */
@media screen and (max-width: 700px) and (min-width: 281px) {
  body {
    min-width: 300px;
    min-height: 600px;
    margin: 0 auto !important;
  }

  .login {
    width: 70vw;
    font-size: 16px !important;
    margin: 0 auto !important;
  }

  .loginText {
    font-size: 16px !important;
    font-weight: 400;
  }



  .loadingVETA {
    width: 83px !important;
    height: 35px !important;
    margin-left: 180px !important;
  }

  .loadingSecondCard {
    margin-left: 21px !important;
    width: 163px !important;
    height: 282px !important;
    margin-bottom: 30vh !important;
    padding-top: 50px !important;
    padding-bottom: 30px !important;
  }

  .loadingSecondBigText {
    font-size: 48px !important;
    font-weight: 700 !important;
  }

  .loadingSecondSmallText {
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  .loadingBusinessText {
    font-size: 24px !important;
    font-weight: 700 !important;
    margin-bottom: -5px !important;
  }

  .loadingBusinessSmallText {
    font-size: 15px !important;
    font-weight: 400 !important;
    margin-bottom: -5px !important;
  }

  .loginStoreIdInput {
    height: 60px !important;
    margin-bottom: 12px !important;
  }

  .loginNextBtn {
    border-radius: 5px !important;
    height: 40px !important;
  }

  .loginSignUpBtn {
    border-radius: 5px !important;
    /* width: 70% !important; */
  }

  .loginSmallText {
    font-size: 13px !important;
    font-weight: 400 !important;
  }

  .storeMainText {
    font-size: 26px !important;
    font-weight: 700 !important;
  }

  .storeCloseBox {
    width: 328px !important;
    /* height: 202px !important; */
    padding: 5% !important;
  }

  .storeCloseBoxMainText {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .storeCloseBoxInsideText {
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .storeCloseBoxDot {
    width: 8px !important;
    height: 8px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .storeOpenBox {
    width: 328px !important;
    padding: 5% !important;
  }

  .storeOpenBoxMainText {
    font-size: 26px !important;
    font-weight: 700 !important;
  }

  .mainBtnText {
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .storeCloseModal {
    width: 359px !important;
    height: 527px !important;
  }

  .modalExitBtn {
    width: 25px !important;
    height: 25px !important;
  }

  .storeCloseMailText {
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  .storeCloseMailSmallText {
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .storeCloseBtn {
    width: 125px !important;
    height: 43px !important;
  }

  .storeCloseBtnText {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .datePicker {
    width: 258px !important;
    height: 34px !important;
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .mainNavberImage {
    /* width: 80px !important; */
    height: 30px !important;
  }

  .mainNavberSmallText {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .mainNavberBigText {
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  .mainNavberdatePicker {
    width: 106px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  .mainboardMainText {
    font-size: 17px !important;
    font-weight: 700 !important;
    color: black !important;
  }

  .mainboardCard {
    width: 80% !important;
    height: 80px !important;
  }

  .mainboardCardImage {
    width: 67px !important;
    height: 67px !important;
  }

  .mainboardCardText {
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  .mainmenuImage {
    width: 67px !important;
    height: 67px !important;
    margin-left: 5% !important;
  }

  .mainmenuText {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .menuBox {
    margin-top: 1vh !important;
  }

  .mainmenuHome {
    width: 36px !important;
    height: 36px !important;
    margin-right: 10% !important;
  }

  .saleTableBox {
    max-width: 93vw !important;
    margin-top: 10% !important;
    /* width: 358px !important; */
    /* height: 572px !important; */
  }

  .saleTableBottomBox {
    width: 93vw !important;
    /* width: 93vw !important;
    position : fixed;
    bottom : 0;
    left :3.5%;
    text-align:center; */
  }

  .saleTableBottomText {
    font-size: 15px !important;
    font-weight: 400 !important;
    height: 100% !important;
  }

  .tableHead {
    height: 40px !important;
  }

  .tableHeadText {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .tableHeadBoldText {
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  .tableBodyText {
    font-size: 9px !important;
    font-weight: 400 !important;
  }

  .tableBody {
    height: 50px !important;
  }

  .tableBodyBigText {
    font-size: 10px !important;
    font-weight: 700 !important;
  }

  .tableBodyBigBigText {
    font-size: 11px !important;
    font-weight: 700 !important;
  }

  .transactionBottomBtn {
    border-radius: 5px !important;
    width: 50% !important;
    height: 43px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .transactionTwoBottomBtn {
    border-radius: 5px !important;
    width: 30% !important;
    height: 43px !important;
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .storeAddBtn {
    margin-top: 1vh !important;
    width: 48px !important;
    height: 48px !important;
    font-size: 40px !important;
    font-weight: 700 !important;
  }

  .storeAddBtnText {
    margin-bottom: 8px !important;
  }

  .storeTableBox {
    max-width: 93vw !important;
    margin-top: 5% !important;
    width: 93vw !important;
    height: 75% !important;
  }

  .calculationTableBox {
    /* max-width: 93% !important; */
    margin-top: 10% !important;
    width: 93vw !important;
    height: 65% !important;
  }

  .dateText {
    font-size: 15px !important;
  }

  .modalMainClose {
    max-width: 93vw !important;
    width: 93vw !important;
    height: 50% !important;
  }

  .modalMainCloseText {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .modalMainSmallBtn {
    margin-top: 5% !important;
    border-radius: 5px !important;
    width: 125px !important;
    height: 43px !important;
  }

  .modalMainSmallBtnText {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .modalMainTodayClose {
    width: 359px !important;
    /* height: 527px !important; */
  }

  .modalMainInsideDefaultText {
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  .modalMainInsideDefaultDot {
    width: 8px !important;
    height: 8px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .modalSaleIncome {
    width: 359px !important;
    height: 474px !important;
  }

  .modalSaleMainText {
    font-size: 19px !important;
  }

  .modalSaleDetailMainText {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .modalSaleDetailCancel {
    width: 359px !important;
    height: 385px !important;

  }

  .modalSaleDetailCancelMainText {
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  .modalSaleDetailCancelSmallText {
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .modalTxSendMail {
    width: 359px !important;
    /* height: 304px !important; */
    height: 60vw !important;
  }

  .modalTxSendMailMainText {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .modalTxCashReceiptMainText {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .modalTxCashReceiptHeadText {
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 32px !important;
    margin-bottom: 16px !important;
  }

  .modalTxCashReceiptFirstMargin {
    margin-bottom: 16px !important;
  }

  .modalTxCashReceiptSecondMarginTop {
    margin-top: 14px !important;
  }

  .modalTxCashReceiptSecondMarginBottom {
    margin-bottom: 14px !important;
  }

  .modalTxCashReceiptMoreMarginBottom {
    margin-bottom: 28px !important;
  }

  .modalTxCashReceiptMoreMarginTop {
    margin-top: 28px !important;
  }

  .modalCalculation {
    width: 348px !important;
    /* height: 699px !important; */
  }

  .modalCalculationDefaultText {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .modalCalculationAmountText {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .modalCalculationAmountInput {
    width: 144.5px !important;
    height: 27px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .modalCalculationDateInput {
    width: 185px !important;
    height: 27px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .modalCalculationBottomText {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .modalCalculationBottomDot {
    width: 6px !important;
    height: 6px !important;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
  }

  .modalCalculationBtn {
    width: 182px !important;
    height: 43px !important;
    border-radius: 5px !important;
  }

  .modalCalculateConfirm {
    width: 359px !important;
    height: 258px !important;
  }

  .modalCalculateConfirmMainText {
    font-size: 16px !important;
    font-weight: 400 !important;
  }

  .modalStoreConfirm {
    width: 359px !important;
    height: 304px !important;
  }

  .modalStoreConfirmMainText {
    font-size: 20px !important;
    font-weight: 700 !important;
  }

  .modalStoreAddManagerTextHeight {
    /* margin-bottom: 20px !important;
    margin-left: 10px !important; */
    width: 100px !important;
    height: 24px !important;
  }

  .modalStoreAddManagerInput {
    width: 150px !important;
    height: 25px !important;
    margin-right: 20px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  .modalStoreCheckBox {
    width: 15px !important;
    height: 15px !important;
  }

  .modalUpdateManager {
    width: 90vw !important;
    min-height: 25% !important;
    /* height: 697px !important; */
  }

  .modalUpdateManagerTitleText {
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  .modalUpdateManagerUpdatePwdBtn {
    width: 70px !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    border-radius: 5px !important;
  }

  .modalUpdatePwd {
    min-width: 40vw !important;
    /* min-height: 50vh !important; */
  }

  .txLoadingImage {
    width: 390px !important;
    height: 356px !important;
  }

  .txLoadingText {
    font-size: 26px !important;
    font-weight: 700 !important;
  }

  .serviceLoadingImage {
    width: 347px !important;
    height: 256px !important;
  }

  .serviceLoadingText {
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  .modalInputPlaceholder::placeholder {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .modalInputPlaceholder ::-webkit-input-placeholder {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .modalInputPlaceholder :-moz-placeholder {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .modalInputPlaceholder ::-moz-placeholder {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .modalInputPlaceholder :-ms-input-placeholder {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .modalAddManager {
    width: 359px !important;
    /* height: 635px !important; */
  }

  .modalAddManagerColumnText {
    margin-bottom: 2% !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .modalAddManagerCheckText {
    font-size: 14px !important;
    font-weight: 700 !important;
  }

  .settingBox {
    width: 80% !important;
    height: 50% !important;
  }

  .storeItemImage {
    width: 90px !important;
    height: 90px !important;

  }

  .saleTableBottomBox {
    max-width: 93vw !important;
    /* width: 93vw !important;
    position : fixed;
    bottom : 0;
    left :3.5%;
    text-align:center; */
  }

  .otherTableBottomBox {
    max-width: 93vw !important;
    width: 93vw !important;
    position: fixed;
    bottom: 0;
    left: 3.5%;
    height: 5% !important;
    text-align: center;
  }



  .saleTablePageBox {
    height: 40px !important;
    max-width: 93vw !important;
  }

  .pageInsideFont {
    color: white !important;
    font-size: 15px !important;
    align-self: center !important;
    height: 100% !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .PageBtn {
    margin-top: 3px !important;
  }

  .salesTableBottomBox {
    max-width: 93vw !important;
    /* width: 1074px !important; */
    ;
    height: 40px !important;
  }

  .txHomeBtn {
    position: relative !important;
    height: 30px !important;
  }

  .txHomeImageAndText {
    position: relative !important;
    height: 30px !important;
    z-index: 3 !important;
  }

  .txHomeBtn:hover {
    background-color: rgb(39, 173, 214, 0.2) !important;
  }

  .transactionTableBox {
    max-width: 93vw !important;
    /* width: 93vw !important; */
    margin-top: 10% !important;
    /* width: 1074px !important; */
    height: 65% !important;
    max-height: 65vh !important;
  }

  .txDetailModal {
    min-width: 80vw !important;
    min-height: 40vh !important;
  }

  .salesSmallBtn {
    border-radius: 5px !important;
    width: 100% !important;
    min-width: 70px !important;
    height: 40px !important;
  }

  .salesSmallOneBtn {
    border-radius: 5px !important;
    width: 100% !important;
    min-width: 140px !important;
    height: 40px !important;
  }

  .itemBoxs {
    min-width: 200px !important;
    min-height: 150px !important;
    /* max-height: 250px !important; */
    /* width: 50% !important; */
    font-size: 13px !important;
    /* max-height: 15vw !important; */
  }

  .salesCategory {
    width: 100%;
    /* position:sticky !important;
    top: 0 !important; */
    padding: 10px;
    border-bottom: 1px solid rgba(217, 217, 217, 1) !important;
    font-size: 10px;
  }

  .salesCategoryText {
    font-size: 12px !important;
    font-weight: 700 !important;
  }

  .itemBoxComponent {
    margin-top: 10% !important;
  }

  .cashreceiptBtn {
    height: 40px !important;
    font-size: 20px !important;
    border-radius: 5px !important;
  }

  .storeOpenBoxInput {
    text-align: center !important;
    height: 30px !important;
    width: 50% !important;
    font-size: 15px !important;
  }

  .userBtn {
    align-items: center !important;
    border: 1px solid rgba(217, 217, 217, 1) !important;
    border-radius: 4px !important;
    margin-right: 0px !important;
    box-sizing: border-box !important;
    text-align: center !important;
    height: 24px !important;
    width: 75px !important;
    color: black !important;
    background-color: rgba(0, 140, 255, 0.2) !important;
  }

  .navbarText {
    margin-bottom: 2% !important;
  }
}



/* 웹 전용 */
@media screen and (min-width: 700px) {
  body {
    margin: 0 auto !important;
  }

  .login {
    width: 50vw;
    font-size: 48px !important;
    margin: 0 auto !important;
  }

  .loginText {
    font-size: 20px !important;
    font-weight: 400;
  }



  .loadingVETA {
    width: 249px !important;
    height: 105px !important;
    margin-left: 540px !important;
  }

  .loadingSecondCard {
    margin-left: 63px !important;
    width: 163px !important;
    height: 489px !important;
    margin-bottom: 30vh !important;
    padding-top: 150px !important;
    padding-bottom: 90px !important;
  }

  .loadingSecondBigText {
    font-size: 144px !important;
    font-weight: 700 !important;
  }

  .loadingSecondSmallText {
    font-size: 72px !important;
    font-weight: 700 !important;
  }

  .loadingBusinessText {
    font-size: 48px !important;
    font-weight: 700 !important;
    margin-bottom: -15px !important;
  }

  .loadingBusinessSmallText {
    font-size: 25px !important;
    font-weight: 400 !important;
    margin-bottom: -15px !important;
  }

  .loginStoreIdInput {
    height: 60px !important;
    margin-bottom: 24px !important;
  }

  .loginNextBtn {
    border-radius: 15px !important;
    height: 50px !important;
  }

  .loginSignUpBtn {
    border-radius: 15px !important;
    margin: 10px 0px 10px 0px;
    /* width: 50% !important; */
  }

  .loginSmallText {
    font-size: 39px !important;
    font-weight: 400 !important;
  }

  .storeMainText {
    font-size: 40px !important;
    font-weight: 700 !important;
  }

  .storeCloseBox {
    width: 80% !important;
    padding: 1% !important;
  }

  .storeCloseBoxMainText {
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .storeCloseBoxInsideText {
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .storeCloseBoxDot {
    width: 9px !important;
    height: 9px !important;
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }

  .storeOpenBox {
    width: 80vw !important;
    padding: 1% !important;
  }

  .storeOpenBoxMainText {
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .mainBtnText {
    height: 8vh !important;
    font-size: 40px !important;
    font-weight: 400 !important;
  }

  .storeCloseModal {
    min-width: 50% !important;
    min-height: 50vh !important;
    max-height: 80vh !important;
  }

  .modalExitBtn {
    position: relative !important;
    top: 1vh !important;
    margin-left: auto !important;
    width: 50px !important;
    height: 50px !important;
  }

  .storeCloseMailText {
    font-size: 60px !important;
    font-weight: 700 !important;
  }

  .storeCloseMailSmallText {
    font-size: 30px !important;
    font-weight: 400 !important;
  }

  .storeCloseBtn {
    width: 300px !important;
    height: 75px !important;
  }

  .storeCloseBtnText {
    font-size: 40px !important;
    font-weight: 700 !important;
  }

  .datePicker {
    width: 774px !important;
    height: 102px !important;
    font-size: 60px !important;
    font-weight: 400 !important;
  }

  .mainNavberImage {
    /* width: 300px !important; */
    height: 70px !important;
  }

  .mainNavberSmallText {
    font-size: 24px !important;
    font-weight: 400 !important;
  }

  .mainNavberBigText {
    font-size: 25px !important;
    font-weight: 400 !important;
  }

  .mainNavberdatePicker {
    width: 318px !important;
    font-size: 45px !important;
    font-weight: 400 !important;
  }

  .mainboardMainText {
    font-size: 30px !important;
    font-weight: 700 !important;
    color: black !important;
  }

  .mainboardCard {
    width: 80% !important;
    height: 10vh !important;
  }

  .mainboardCardImage {
    width: 8vh !important;
    height: 8vh !important;
  }

  .mainboardCardText {
    font-size: 40px !important;
    font-weight: 700 !important;
  }

  .mainmenuImage {
    width: 75px !important;
    height: 75px !important;
    margin-left: 5% !important;
  }

  .mainmenuText {
    /* margin-top: 6vh !important; */
    font-size: 40px !important;
    font-weight: 700 !important;
  }

  .menuBox {
    margin-top: 1vh !important;
  }

  .mainmenuHome {
    width: 60px !important;
    height: 60px !important;
    margin-right: 10% !important;
  }

  .saleTableBox {
    max-width: 93vw !important;
    margin-top: 2% !important;
    /* width: 1074px !important; */
    /* height: 80% !important; */

  }

  .saleTableBottomBox {
    max-width: 93vw !important;
    /* width: 93vw !important;
    position : fixed;
    bottom : 0;
    left :3.5%;
    text-align:center; */
  }

  .otherTableBottomBox {
    max-width: 93vw !important;
    width: 93vw !important;
    position: fixed;
    bottom: 0;
    left: 3.5%;
    text-align: center;
  }

  .saleTableBottomText {
    font-size: 26px !important;
    font-weight: 400 !important;
    /* height: 117px !important; */
  }

  .tableHead {
    height: 60px !important;
  }

  .tableHeads {
    padding: 1% !important;
    width: 100px !important;
  }

  .tableHeadText {
    font-size: 25px !important;
    font-weight: 400 !important;
  }

  .tableHeadBoldText {
    font-size: 25px !important;
    font-weight: 700 !important;
  }

  .tableBodyText {
    font-size: 18px !important;
    font-weight: 400 !important;
  }

  .tableBody {
    height: 50px !important;
  }

  .tableBodyBigText {
    font-size: 19px !important;
    font-weight: 700 !important;
  }

  .tableBodyBigBigText {
    font-size: 18px !important;
    font-weight: 700 !important;
  }

  .transactionBottomBtn {
    position: fixed !important;
    bottom: 2% !important;
    border-radius: 15px !important;
    left: 3.5% !important;
    width: 93% !important;
    height: 60px !important;
    font-size: 35px !important;
    font-weight: 700 !important;
  }

  .transactionTwoBottomBtn {
    border-radius: 15px !important;
    width: 40% !important;
    max-width: 40% !important;
    height: 80px !important;
    font-size: 35px !important;
    font-weight: 700 !important;
  }

  .storeAddBtn {
    width: 70px !important;
    height: 70px !important;
    font-size: 75px !important;
    font-weight: 700 !important;
  }

  .storeAddBtnText {
    margin-bottom: 20px !important;
  }

  .storeTableBox {
    max-width: 93% !important;
    margin-top: 10px !important;
    /* width: 1074px !important; */
    height: 75% !important;
  }

  .storeItemBox {
    max-width: 100% !important;
    /* margin-top: 5% !important; */
    height: 80% !important;
  }

  .calculationTableBox {
    max-width: 93vw !important;
    margin-top: 2% !important;
    /* width: 1074px !important; */
    height: 65% !important;
  }

  .dateText {
    font-size: 25px !important;
  }

  .modalMainClose {
    max-width: 93vw !important;
    width: 50vw !important;
    height: 40vh !important;
  }

  .modalMainCloseText {
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .modalMainSmallBtn {
    border-radius: 15px !important;
    width: 40% !important;
    height: 60px !important;
  }

  .modalMainSmallBtnText {
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .modalMainTodayClose {
    min-width: 65vw !important;
    /* width: px !important; */
    /* height: 80vh !important; */
  }

  .modalMainInsideDefaultText {
    font-size: 24px !important;
    font-weight: 400 !important;
  }

  .modalMainInsideDefaultDot {
    width: 12px !important;
    height: 12px !important;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }


  .modalSaleIncome {
    min-width: 25vw !important;
    width: 1077px !important;
    height: 1422px !important;
  }

  .modalSaleMainText {
    font-size: 57px !important;
  }

  .modalSaleDetailMainText {
    font-size: 40px !important;
    font-weight: 700 !important;
  }

  .modalSaleDetailCancel {
    min-width: 60vw !important;
    /* width: 1077px !important; */
    height: 50vh !important;

  }

  .modalSaleDetailCancelMainText {
    font-size: 36px !important;
    font-weight: 700 !important;
  }

  .modalSaleDetailCancelSmallText {
    font-size: 30px !important;
    font-weight: 400 !important;
  }

  .modalTxSendMail {
    min-width: 40vw !important;
    width: 70vw !important;
    min-height: 40% !important;
  }

  .modalTxSendMailMainText {
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .modalTxCashReceiptMainText {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .modalTxCashReceiptHeadText {
    font-size: 20px !important;
    font-weight: 400 !important;
    margin-top: 1% !important;
    margin-bottom: 12px !important;
  }

  .modalTxCashReceiptFirstMargin {
    margin-bottom: 30px !important;
  }

  .modalTxCashReceiptSecondMarginTop {
    margin-top: 30px !important;
  }

  .modalTxCashReceiptSecondMarginBottom {
    margin-bottom: 30px !important;
  }

  .modalTxCashReceiptMoreMarginBottom {
    margin-bottom: 30px !important;
  }

  .modalTxCashReceiptMoreMarginTop {
    margin-top: 30px !important;
  }

  .modalCalculation {
    min-width: 50vw !important;
    /* width: 1044px !important; */
    /* height: 2097px !important; */
  }

  .modalCalculationDefaultText {
    font-size: 25px !important;
    font-weight: 400 !important;
  }

  .modalCalculationAmountText {
    font-size: 40px !important;
    font-weight: 700 !important;
  }

  .modalCalculationAmountInput {
    margin-left: auto !important;
    width: 30% !important;
    height: 50px !important;
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .modalCalculationDateInput {
    width: 80% !important;
    height: 50px !important;
    font-size: 25px !important;
    font-weight: 400 !important;
  }

  .modalCalculationBottomText {
    font-size: 24px !important;
    font-weight: 400 !important;
  }

  .modalCalculationBottomDot {
    width: 12px !important;
    height: 12px !important;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .modalCalculationBtn {
    /* width: 546px !important; */
    height: 70px !important;
    border-radius: 15px !important;
  }

  .modalCalculateConfirm {
    min-width: 85vw !important;
    /* width: 1077px !important; */
    height: 50% !important;
  }

  .modalCalculateConfirmMainText {
    font-size: 30px !important;
    font-weight: 400 !important;
  }

  .modalStoreConfirm {
    min-width: 40vw !important;
    width: 1077px !important;
    min-height: 40vh !important;
    max-height: 60vh !important;
  }

  .modalStoreConfirmMainText {
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .modalStoreAddManagerTextHeight {
    height: 40px !important;
    width: 150px !important;
  }

  .modalStoreAddManagerInput {
    max-width: 500px !important;
    width: 50% !important;
    height: 40px !important;
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .modalStoreCheckBox {
    width: 30px !important;
    height: 30px !important;
  }

  .modalCalCheckBox {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 20px !important;
    height: 20px !important;
  }

  .modalUpdateManager {
    min-width: 60vw !important;
    width: 80% !important;
    min-height: 50vh !important;
    /* max-height: 100vh !important; */
  }

  .modalUpdateManagerTitleText {
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .modalUpdateManagerUpdatePwdBtn {
    width: 150px !important;
    font-size: 25px !important;
    font-weight: 700 !important;
    border-radius: 15px !important;
  }

  .modalUpdatePwd {
    min-width: 70vw !important;
    /* width: 1077px !important; */
    /* min-height: 50vh !important; */
  }

  .txLoadingImage {
    width: 1170px !important;
    height: 1068px !important;
  }

  .txLoadingText {
    font-size: 78px !important;
    font-weight: 700 !important;
  }

  .serviceLoadingImage {
    width: 600px !important;
    height: 400px !important;
  }

  .serviceLoadingText {
    font-size: 40px !important;
    font-weight: 700 !important;
  }

  .modalInputPlaceholder::placeholder {
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .modalInputPlaceholder ::-webkit-input-placeholder {
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .modalInputPlaceholder :-moz-placeholder {
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .modalInputPlaceholder ::-moz-placeholder {
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .modalInputPlaceholder :-ms-input-placeholder {
    font-size: 20px !important;
    font-weight: 400 !important;
  }

  .modalAddItem {
    min-width: 25% !important;
    min-height: 50% !important;
  }

  .modalAddManager {
    width: 75vw !important;
    max-width: 1000px !important;
    /* max-height: 100% !important; */
  }

  .modalAddManagerColumnText {
    margin-bottom: 2% !important;
    font-size: 20px !important;
    /* width: 100% !important; */
    font-weight: 400 !important;
  }

  .modalAddManagerCheckText {
    font-size: 25px !important;
    font-weight: 700 !important;
  }

  .settingBox {
    margin-top: 1vh !important;
    margin-left: 10vw !important;
    margin-right: 10vw !important;
    width: 80vw !important;
    min-height: 30vh !important;
  }

  .settingBoxText {
    color: white;
    font-size: 24px !important;
    font-weight: 700 !important;
  }

  .settingInput {
    height: 35px !important;
    margin-bottom: 10px !important;
  }

  .settingInputText {
    font-size: 17px !important;
    font-weight: 400;
  }

  .settingInputMainText {
    color: white;
    font-size: 17px !important;
    font-weight: 400;
  }

  .settingBtn {
    border-radius: 15px !important;
    height: 50px !important;
  }

  .settingStoreBox {
    border-radius: 20px !important;
    background-color: #008BFF;
    width: 90%;
    padding: 20px;
    margin-bottom: 20px;
  }

  .settingPresidentBox {
    border-radius: 20px !important;
    background-color: #008BFF;
    width: 90%;
    padding: 20px;
  }

  .categoryText {
    font-size: 30px;
  }

  .categoryText:hover {
    font-size: 31px;
  }

  .storeAddDetailBtn {
    margin-top: 2vh !important;
    width: 150px !important;
    height: 50px !important;
    font-size: 30px !important;
    font-weight: 700 !important;
  }

  .storeRollBtn {
    /* background-color: 'rgba(25, 25, 25, 0.8)' !important; */
    margin-top: 2vh !important;
    height: 5vw !important;
    width: 3vw !important;
    size: 10px !important;
    z-index: 5 !important;
  }

  .storeRollBtn:hover {
    background-color: 'rgba(0, 0, 0, 0)' !important;
  }

  .storeRollBtn:active {
    background-color: 'rgba(0, 0, 0, 0)' !important;
  }

  .storeRollBtn:focus {
    background-color: 'rgba(0, 0, 0, 0)' !important;
  }

  .storeAddDetailBtn:hover {
    background-color: 'rgba(0, 131, 153, 0.7)' !important;
  }

  .storeAddDetailBtn:active {
    background-color: 'rgba(0, 131, 153, 0.7)' !important;
  }

  .storeAddDetailBtn:focus {
    background-color: 'rgba(0, 131, 153, 0.7)' !important;
  }

  .itemAddBox {
    min-width: 80% !important;
    width: 100% !important;
  }

  .bigCategory {
    min-width: 10% !important;
    color: white !important;
    background-color: #008BFF !important;
    text-align: center !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    border-radius: 10px !important;
  }

  .bigCategoryUpdBtn {
    display: none !important;
    min-width: 25px !important;
  }

  .bigCategory:hover .bigCategoryUpdBtn {
    display: block !important;
  }

  .storeOpenBoxInput {
    text-align: center !important;
    height: 35px !important;
    width: 30% !important;
    font-size: 25px !important;
  }

  .storeItemImage {
    width: 100px !important;
    height: 100px !important;
  }

  .sirenOrderModal {
    min-width: 80vw !important;
    width: 100% !important;
    min-height: 60vh !important;
  }

  .saleTablePageBox {
    height: 40px !important;
    max-width: 93vw !important;
  }

  .pageInsideFont {
    color: white !important;
    margin-top: 5px !important;
    font-size: 25px !important;
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .PageBtn {
    margin-top: 10px !important;
  }

  .salesTableBottomBox {
    max-width: 93vw !important;
    /* width: 1074px !important; */
    height: 40px !important;
  }

  .txHomeImageAndText {
    position: relative !important;
    height: 50px !important;
    z-index: 3 !important;
  }

  .txHomeBtn {
    position: relative !important;
    height: 50px !important;
    margin-left: 85% !important;
    z-index: 4 !important;
  }

  .txHomeBtn:hover {
    background-color: rgb(39, 173, 214, 0.2) !important;
  }

  .transactionTableBox {
    max-width: 93vw !important;
    margin-top: 1% !important;
    /* width: 1074px !important; */
    height: 50vh !important;
    /* max-height: 50vh !important; */
  }

  .txDetailModal {
    min-width: 80vw !important;
    min-height: 40vh !important;
  }

  .salesSmallBtn {
    border-radius: 10px !important;
    width: 100% !important;
    min-width: 70px !important;
    height: 50px !important;
  }

  .salesSmallOneBtn {
    border-radius: 10px !important;
    width: 100% !important;
    min-width: 140px !important;
    height: 50px !important;
  }

  .salesCategory {
    width: 100%;
    /* position:sticky !important;
    top: 0 !important; */
    padding: 10px;
    border-bottom: 1px solid rgba(217, 217, 217, 1) !important;
    font-size: 15px;
  }

  .salesCategoryText {
    font-size: 15px !important;
    font-weight: 700 !important;
  }

  .itemBoxs {
    min-width: 250px !important;
    /* max-height: 270px !important; */
    min-height: 150px !important;
    width: 20vw !important;
    font-size: 18px !important;
    /* max-height: 15vw; */
  }

  .cashreceiptBtn {
    height: 60px !important;
    font-size: 30px !important;
    border-radius: 10px !important;
  }

  .storeText {
    margin-top: 15px !important;
  }

  .userBtn {
    align-items: center !important;
    border: 1px solid rgba(217, 217, 217, 1) !important;
    border-radius: 4px !important;
    box-sizing: border-box !important;
    text-align: center !important;
    padding-right: 14px !important;
    height: 40px !important;
    width: 100px !important;
    color: black !important;
    background-color: rgba(0, 140, 255, 0.2) !important;
  }

  .navbarText {
    margin-bottom: 2% !important;
  }

  .discountModal {
    min-width: 60% !important;
    min-height: 60% !important;
  }
}

body::before {
  left: 0;
}

body::after {
  right: 0;
}

th {
  text-transform: none !important;
  font-family: "DM Sans" !important;
  color: black;
}



/* sbCHOI */
@media screen and (max-height: 950px) {
  .loginStoreIdInput {
    height: 45px !important;
    margin-bottom: 20px !important;
  }

}


.headerText {
  font-weight: 900;
}