.switch {
  width: 60px;
  height: 30px;
  background-color: #E2E8F0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.switch.on{ 
  background-color: #66ff00;
}
.slider {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.switch.on .slider { 
  transform: translateX(15px);
}

.switch.off .slider {
  transform: translateX(-15px);
}
