.circle-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #46D1E8;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  scroll-snap-align: start;
}
.circleColor {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid #46D1E8;
  margin: 0 10px;
  background-color: #46D1E8;
  cursor: pointer;
  transition: background-color 0.3s ease;
  scroll-snap-align: start;
}


.circle:hover {
  background-color: rgba(70, 209, 232, 0.2);
}

/* App.css */

.slide-up-container {
  animation: slideUp 0.5s ease-in-out forwards;
  transform: translateY(400%);
}

@keyframes slideUp {
  to {
    transform: translateY(0);
  }
}

.unmount{ animation: 0.5s ease-in-out slideDown; }
@keyframes slideDown {
  0%{
      transform: translateY(0px);
  }
  100%{
      transform: translateY(400%);
  }
}
.slide-up-content-closed {
  transform: translateY(100%);
}