/* Ensure event titles wrap */
.fc-event-title, .fc-event-time {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    word-wrap: break-word;
}

/* Ensure the event box grows in height if needed */
.fc-daygrid-event {
    display: block;
    white-space: normal;
    height: auto;
    min-height: auto;
}
