.datePicker {
  display: flex;
  align-items: center;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
  box-sizing: border-box;
  width: '258px';
  height: '34px';
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  padding-right: 14px;
  background-color: rgba(0, 140, 255, 0.2);
}

.datePickerService {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-align: right;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 400;
  padding-left: 0px;
  width: 106px;
  background-color: #46D1E8;
}

@media screen and (min-width: 768px) {
  .datePicker {
    display: flex;
    align-items: center;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 4px;
    box-sizing: border-box;
    width: 50% !important;
    height: 40px !important;
    text-align: center;
    font-size: 30px !important;
    font-weight: 400;
    /* padding-right: 14px; */
    background-color: rgba(0, 140, 255, 0.2);
  }

  .txDatePicker {
    display: flex;
    align-items: center;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    padding-right: 14px;
    background-color: rgba(0, 140, 255, 0.2);
    width: 200px !important;
    height: 40px !important;
    font-size: 25px !important;
    font-weight: 400 !important;
  }

  .datePickerService {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-align: right;
    text-decoration: underline;
    font-size: 15px;
    font-weight: 400;
    padding-left: 0px;
    width: 106px;
    background-color: #46D1E8;
  }

  .react-datepicker {
    width: 50% !important;
    /* height: 60% !important; */
    font-size: 30px;
  }

  .react-datepicker__month-container {
    width: 100% !important;
    height: 100% !important;
  }
  .react-datepicker__month-wrapper{
    height:100px;
  }
  .react-datepicker__header {
    padding-top: 30px;
    font-size: 30px;
  }
  
  .react-datepicker__month-text{
    font-size: 30px;
    margin: 30px !important;
  }
  .react-datepicker__month {
    margin: 20px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 10% !important;
    line-height: 40px;
    margin: 2%;
  }

  .react-datepicker__current-month {
    font-size: 40px !important;
  }

  .react-datepicker__navigation {
    top: 40px !important;
    /* line-height: 30px; */
    border: 15px solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: black;
    left: 1%;
  }

  .react-datepicker__navigation--next {
    border-left-color: black;
    right: 1%;
  }
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  .datePicker {
    display: flex;
    align-items: center;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 4px;
    box-sizing: border-box;
    width: 50% !important;
    height: 25px !important;
    text-align: center;
    font-size: 15px !important;
    font-weight: 400;
    /* padding-right: 14px; */
    background-color: rgba(0, 140, 255, 0.2);
  }

  .txDatePicker {
    display: flex;
    align-items: center;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    padding-right: 14px;
    background-color: rgba(0, 140, 255, 0.2);
    width: 100px !important;
    height: 25px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .datePickerService {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-align: right;
    text-decoration: underline;
    font-size: 15px;
    font-weight: 400;
    padding-left: 0px;
    width: 66px;
    background-color: #46D1E8;
  }

  .react-datepicker {

    width: 100% !important;
    /* height: 50% !important; */
    font-size: 20px;
  }

  .react-datepicker__month-container {
    width: 100% !important;
    height: 100% !important;
  }
  .react-datepicker__month-wrapper{
    height:100px;
  }
  .react-datepicker__header {
    padding-top: 20px;
    font-size: 20px;
  }
  
  .react-datepicker__month-text{
    font-size: 20px;
    margin: 20px !important;
  }
  .react-datepicker__month {
    margin: 10px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 10% !important;
    font-size: 20px;
    line-height: 15px;
    margin: 2%;
  }

  .react-datepicker__current-month {
    font-size: 25px !important;
  }

  .react-datepicker__navigation {
    top: 5%;
    line-height: 15px;
    border: 15px solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: black;
    left: 1%;
  }

  .react-datepicker__navigation--next {
    border-left-color: black;
    right: 1%;
  }
  .react-datepicker__header{
    font-size:30px !important;
  }
}