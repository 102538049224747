.modal {
    /* 모달창 크기 */
    max-width: 85%;
    max-height: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.screen-box {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.screen-box::-webkit-scrollbar {
    display: none
        /* Chrome , Safari , Opera */
}

.table-box {
    -ms-overflow-style: thin;
    /* IE and Edge */
    scrollbar-width: thin;
    /* Firefox */
}

.table-box::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    /* Chrome , Safari , Opera */
}
.table-box::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #6b8de3, #a1c4fd);
    border-radius: 10px;
    border: 2px solid #ffffff; /* Adds some padding around the thumb */
}

.table-box::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}